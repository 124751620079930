@import './theme.scss';

// xl-device=====
@mixin fifteen-down-device {
    @media (max-width: 1500px) {
        @content;
    }
}

@mixin xxl-down-device {
    @media (max-width: 1399px) {
        @content;
    }
}

@mixin xl-down-device {
    @media (max-width: 1199px) {
        @content;
    }
}

@mixin xl-device {
    @media (min-width: 1200px) and (max-width: 1399px) {
        @content;
    }
}

@mixin lg-device {
    @media (min-width: 992px) and (max-width: 1199px) {
        @content;
    }
}

@mixin lg-up-device {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin lg-down-device {
    @media (max-width: 991px) {
        @content;
    }
}

// md-device============
@mixin md-device {
    @media (min-width: 768px) and (max-width: 991px) {
        @content;
    }
}

@mixin md-up-device {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin md-down-device {
    @media (max-width: 767px) {
        @content;
    }
}

// sm-device
@mixin sm-device {
    @media (min-width: 576px) and (max-width: 768px) {
        @content;
    }
}

@mixin sm-down-device {
    @media (max-width: 576px) {
        @content;
    }
}

//
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dapp-core-component__main__btn-primary {
    // background-color: white !important;
    // border-color: white !important;
    // box-shadow: none !important;
    color: white !important;
    
    div {
        box-shadow: 0px 2px 4px rgb(0 0 0 / 16%) !important;
    }
    
}

.dapp-modal-dialog-content {
    background-color: $secondary !important;
    border: none !important;
    color: white !important;
    // margin-left: 1rem !important;
    // margin-right: 1rem !important;
    
    .dapp-modal-dialog-header {
        padding-top: 20px !important;
        background-color: $secondary !important;
        color: white !important;
    }
}

@media screen and (max-width: 480px) {
    .dapp-modal-dialog-content {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
}

.dapp-core-component__main__card {
    background-color: $secondary !important;
    color: white !important;
}

.dapp-core-component__main__btn {
    // color: black !important;
    // background-color: white !important;
    border: none !important;
    text-decoration: none !important;
}

.dapp-modal-dialog-close-button {
    margin: 5px !important;
}

.main-tab {
    position: relative;
    padding: 10px 0px;
    box-shadow: 3px 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.main-tab-back-tab-0 {
    z-index: 10;
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: $primary;
    border-radius: 5px;
    left: 0% !important;
    transition: .4s all ease-in-out;
    // transition-duration: 300s;
}

.main-tab-back-tab-1 {
    z-index: 10;
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: $primary;
    border-radius: 5px;
    left: 50% !important;
    transition: .4s all ease-in-out;
    // transition-duration: 300s;
}


.main-tab-individual {
    z-index: 20;
    position: relative;
    width: 100px;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 500;
    cursor: pointer;
    transition: .4s all ease-in-out;
    // color: white;
}


.info-item {
    padding: 30px 25px;
    box-shadow: 3px 4px 30px rgba(136, 136, 136, 0.18);
    border-radius: 10px;
}

.testimonial-single2 {
    box-shadow: 3px 4px 30px rgba(136, 136, 136, 0.18);
    border-radius: 5px;
    background-color: white;
    position: relative;
    z-index: 1;
    padding: 30px 25px;
    
    .testi2-bg {
        position: absolute;
        bottom: 0px;
        right: 0px;
        z-index: -1;
        width: 210px;
    }
}

.service-section {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.watermark1-bg {
    position: absolute;
    left: -80px;
    top: 0;
    z-index: -1;
    max-width: 100%;
    
    // @include xl-down-device() {
        //     display: none;
        //     visibility: hidden;
        // }
}

.watermark2-bg {
    position: absolute;
    right: -80px;
    top: 0;
    z-index: -1;
    max-width: 100%;
    
    // @include xl-down-device() {
        //     display: none;
        //     visibility: hidden;
        // }
}

.staking-individual-info {
    @media (max-width: 576px) {
        display: none !important;
    }
}

.staking-all-in-one-info {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    
    @media (min-width: 576px) {
        display: none !important;
    }
}

.dex-button-container {
    display: flex;
    justify-content: center;
    margin: auto;
}

.btn-redirect-dex {
    background: none;
    border: none;
    font-size: 2rem;
    transition: transform .4s;

    &:hover {
      transform: scale(1.2);
    }
}

.auditor-container {
    display: flex;
    justify-content: center;
    gap: 36px;
}

.img-audited {
    width: 210px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.img-auditor {
    display: block;
    width: 140px;
    height: auto;
    transition: transform .4s;
    
    &:hover {
        transform: scale(1.2);
    }
}

.eg-btn {
    border-radius: 0px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    transition: all 0.4s ease;
    // text-transform: capitalize;
    font-family: $font-poppins;
    outline: none;
}

.btn--primary2 {
    background: #18212e;
    color: #fff;
    position: relative;
    z-index: 1;
    white-space: nowrap;
    border-radius: 5px;
    font-family: $font-poppins;
    ;
    
    &::before {
        width: 100%;
        height: 100%;
        content: "";
        margin: auto;
        position: absolute;
        top: 0%;
        left: 0%;
        background: #00caeb;
        transition: all 0.52s;
        z-index: -1;
        border-radius: 5px;
    }
    
    &::after {
        width: 100%;
        height: 100%;
        content: "";
        margin: auto;
        position: absolute;
        top: 0%;
        left: 0%;
        background: #00caeb;
        transition: all 0.52s;
        z-index: -1;
        border-radius: 5px;
    }
    
    &:hover {
        color: #fff;
        
        &::before {
            transform: rotateX(90deg);
        }
        
        &::after {
            transform: rotateY(90deg);
        }
    }
}

.btn--lg {
    font-size: 16px;
    font-weight: 600;
    // text-transform: uppercase;
    padding: 10px 25px;
    
    // @include md-down-device() {
        //     padding: 12px 25px;
        // }
}

.btn--md {
    padding: 8px 20px;
    font-size: 16px;
    font-weight: 700;
}

.hover-border2 {
    background: #fff;
    position: relative;
    z-index: 1;
    
    &::before {
        content: "";
        width: 0%;
        height: 2px;
        position: absolute;
        bottom: 0%;
        left: 0%;
        background: #00caeb;
        transition: all 0.52s;
        z-index: -1;
    }
    
    &:hover {
        &::before {
            width: 100%;
        }
    }
}

.section-title2 {
    text-align: center;
    margin-bottom: 30px;
    
    span {
        font-family: $font-cuprum;
        font-size: 2.25rem;
        font-weight: 700;
        color: var(--text-color);
        position: relative;
        display: inline-block;
        margin-bottom: 0;
        padding-bottom: 10px;
        
        &::after {
            content: url("../../assets/img/section-title-v.svg");
            position: absolute;
            right: -50px;
            bottom: -10px;
        }
    }
}

.max-but {
    height: 30px;
    font-size: 1rem;
    border-radius: 5px;
    border: none;
    transition: all .3s ease-in-out;
    padding-left: 10px;
    padding-right: 10px;
    
    &:hover {
        background-color: $primary;
        color: white;
    }
}

.A-form {
    background: #fAfAfA;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #f0f0f0;
}

.A-logo {
    display: flex;
    align-items: center;
    
    img {
        width: 30px;
        height: 30px;
    }
    
    span {
        margin-left: 10px;
        font-size: 1.325rem;
        font-weight: 400;
    }
}

.A-input {
    outline: none;
    border: none;
    background-color: transparent;
    text-align: right;
    height: 50px;
    font-size: 1.875rem;
    font-weight: 700;
    border-radius: 5px 0 0 5px;
    padding-right: 10px;
    width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.B-form {
    // background: #fAfAfA;
    padding: 10px 20px;
    // border-radius: 5px;
    // border: 1px solid #f0f0f0;
}

.B-logo {
    display: flex;
    align-items: center;
    
    img {
        width: 30px;
        height: 30px;
    }
    
    span {
        margin-left: 10px;
        font-size: 1.325rem;
        font-weight: 400;
    }
}

.B-input {
    outline: none;
    border: none;
    background-color: transparent;
    text-align: right;
    height: 50px;
    font-size: 1.875rem;
    font-weight: 700;
    border-radius: 5px 0 0 5px;
    padding-right: 10px;
    width: 100%;
    color: black;
}

.faq-section {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.faq-wrap {
    .faq-item {
        margin-bottom: 24px;
        border: none;
        
        &:last-child {
            margin-bottom: 0;
        }
        
        &.hover-btn {
            padding: 1px 1px 1px 1px;
        }
    }
    
    .accordion-button {
        font-family: $font-cuprum;
        font-weight: 600;
        font-size: 20px;
        background: rgba($primary, 0.09);
        border-radius: 5px;
        color: var(--text-color2);
        cursor: pointer;
        transition: 0.45s ease-in-out;
        padding: 20px 25px;
        padding-right: 60px;
        margin-bottom: 0px;
        line-height: 1.4;
        
        &:focus {
            z-index: unset;
            border-color: unset;
            outline: 0;
            background: $primary;
            box-shadow: 5px 7px 40px rgba(145, 145, 145, 0.1);
        }
        
        &::after {
            flex-shrink: 0;
            width: unset;
            height: unset;
            margin-left: auto;
            background-image: none;
            background-repeat: unset;
            background-size: unset;
            font-family: bootstrap-icons !important;
            position: absolute;
            right: 22px;
            content: "\f4fe";
            transition: unset;
            font-size: 20px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        &:not(.collapsed)::after {
            background-image: none;
            transform: unset;
            font-family: bootstrap-icons !important;
            content: "\f2ea";
            color: var(--white);
        }
        
        &:hover {
            background: $primary;
            color: #fff;
            
            &::after {
                color: var(--white);
            }
        }
        
        &.style-2 {
            box-shadow: 3px 4px 30px rgba(136, 136, 136, 0.1);
            background: var(--white);
            color: var(--text-color2);
            
            &:hover {
                color: var(--white);
                background: var(--primary-color1);
            }
            
            &:focus {
                background: var(--primary-color1);
            }
            
            &:not(.collapsed) {
                box-shadow: 5px 7px 40px rgba(145, 145, 145, 0.1);
                background: var(--primary-color1);
                color: #fff;
                border-radius: 5px 5px 0px 0px;
                font-weight: 600;
            }
        }
    }
    
    .faq-body {
        font-weight: 400;
        font-size: 15px;
        font-family: $font-poppins;
        background: rgba(#27ae60, 0.04);
        color: var(--text-color2);
        border-top: none;
        padding: 15px 25px;
        line-height: 28px;
        text-align: left;
        
        &.style-2 {
            background: rgba(#cf2435, 0.04);
        }
    }
    
    .accordion-button:not(.collapsed) {
        box-shadow: 5px 7px 40px rgba(145, 145, 145, 0.1);
        background: $primary;
        color: #fff;
        border-radius: 5px 5px 0px 0px;
        font-weight: 600;
    }
}

.valar-main {
    font-family: $font-poppins;
}

.vesta-logo {
    height: 2.5rem;
    @media (max-width: 576px) {
        height: 1.75rem;
    }
}

.footer {
    background: $secondary;
    margin-top: 80px;
    color: white;
}

.footer-social {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    
    i {
        color: white;
        width: 34px;
        height: 34px;
        line-height: 34px;
        //   border: 1px solid var(--text-color2);
        text-align: center;
        transition: all 0.42s ease;
        
        &:hover {
            background: $primary;
            // border: 1px solid var(--primary-color1);
        }
    }
}


.social-box {
    font-size: 2rem;
    color: white;
    
    &:hover {
        color: $primary;
    }
}

.egns-lab {
    font-size: 13px;
    font-weight: 600;
    color: $primary;
}

.footer-vesta-snake {
    width: 30rem;
    
    @media (max-width: 576px) {
        width: 80%;
    }
}

//
.header_connect_but {
    display: flex;
    align-items: center;
    gap: 8px;
    border: none;
    
    .wallet_connect_icon {
        width: 26px;
        height: 26px;
        
        background: white;
        border-radius: 50px;
        color: #2A172F;
        
        display: flex;
        justify-content: center;
        align-items: center;
        
        font-size: 1.375rem;
    }
}

.connection-modal {
    width: 450px;
    padding: 20px;
    
    @include sm-down-device() {
        width: 350px;
    }
}

//
// .dapp-core-component__main__btn-primary {
//     // background-color: white !important;
//     // border-color: white !important;
//     box-shadow: none !important;
// }

// .dapp-core-component__main__btn-primary div {
//     box-shadow: none !important;
// }

.dapp-modal-dialog-content {
    background-color: #1B0921 !important;
    border: none !important;
    color: white !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;

    .dapp-modal-dialog-header {
        padding-top: 20px !important;
        background-color: #1B0921 !important;
        color: white !important;
    }
}

.dapp-core-component__main__card {
    background-color: #1B0921 !important;
    color: white !important;
}

.dapp-core-component__main__btn {
    // color: white !important;
    // background-color: $secondary-bg-color !important;
    // border: none !important;
    // text-decoration: none !important;
}

.dapp-modal-dialog-close-button {
    margin: 5px !important;
}

.wallet-login {
    // width: 95% !important;
    background: #1B0921 !important;
    border-radius: 10px !important;
    border: 1px solid rgba(255, 255, 255, 0.06) !important;
    padding: 15px !important;
    color: white !important;
    font-size: 1.25rem !important;
    text-decoration: none !important;

    &:hover {
        background: #2d0f37 !important;
    }
}

.card-body {
    // background: $secondary-bg-color;
    border-radius: 15px;
}

.dapp-core-component__extensionLoginButtonStyles__no-extension-button-wrapper {
    margin-left: 1rem !important;
}

.cls-maiar-extension-1 {
    fill: #fff;
}

.cls-maiar-extension-2 {
    fill: #000;
}

.cls-maiar-app-1 {
    fill: #fff;
}

.cls-maiar-app-2 {
    fill: #000;
}

.cls-webwallet-1 {
    fill: #fff;
}

.cls-webwallet-2 {
    fill: #000;
    fill-rule: evenodd;
}

.wallet-content {
    display: flex;
    align-items: center;
    text-decoration: none !important;

    .wallet-icon {
        width: 40px;
        margin-right: 10px;
    }
}

.dapp-wallet-connect-login-modal {
    justify-content: center !important;
}

.dapp-modal-dialog-wrapper {
    justify-content: center !important;
}

//
.ReactModal__Overlay {
    background: rgba(27, 0, 40, 0.9) !important;
    z-index: 998;
    backdrop-filter: blur(10px);
}

.ReactModal__Content {
    background: #100017 !important;
    border-radius: 15px !important;
    border: none !important;
    padding: 20px 0px !important;
    font-family: $font-poppins !important;
}

.modal-close-but {
    font-size: 2rem;
    color: white;
    cursor: pointer;
}

.dapp-core-component__dappModalStyles__dappModalCloseButton {
    color: black !important;
}

.dapp-core-component__main__btn-dark {
    background: #2d0f37 !important;
}

.ls-action-button {
    border: none !important;
}
